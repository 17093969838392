@font-face {
  font-family: "Bahij-light";
  src: url("../assets/fonts/Bahij_TheSansArabic-Light.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Bahij-regular";
  src: url("../assets/fonts/Bahij_TheSansArabic-Plain.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Bahij-semibold";
  src: url("../assets/fonts/Bahij_TheSansArabic-SemiBold.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Bahij-bold";
  src: url("../assets/fonts/Bahij_TheSansArabic-Bold.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Bahij-extrabold";
  src: url("../assets/fonts/Bahij_TheSansArabic-ExtraBold.ttf");
  font-weight: normal;
  font-style: normal;
}

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-family: "Bahij-regular";
  color: #0b1e2b;
  font-size: 16px;
}

body {
  overflow-x: hidden;
}

.owl-carousel {
  direction: ltr;
}

.tooltip {
  z-index: 1100 !important;
}

.tooltip-inner {
  max-width: 200px;
  padding: 5px 10px;
  color: #fff;
  text-align: center;
  background-color: #2f4657;
  border-radius: 0.25rem;
  font-size: 11px;
  text-transform: uppercase;
}

.tooltip.in {
  opacity: 1;
}

.tooltip .tooltip-arrow {
  display: none;
}

.tooltip.top {
  padding: 5px 0;
}

body {
  padding: 0;
  margin: 0;
}

select {
  display: inline-block;
}

html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  vertical-align: middle;
  background: transparent;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

iframe {
  width: 100%;
  border: 0;
  height: 100%;
}

::-moz-selection {
  background-color: #346cf4;
  color: #fff;
}

::selection {
  background-color: #346cf4;
  color: #fff;
}

a,
a:hover,
a:visited,
a:link {
  text-decoration: none;
  outline: none;
  cursor: pointer;
}

a {
  display: inline-block;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

img {
  vertical-align: middle;
  border-style: none;
  max-width: 100%;
}

p {
  line-height: 1.7;
}

.modal-open {
  overflow: hidden !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: inherit;
  font-weight: normal;
  line-height: 1.3;
}

.modal-backdrop.in {
  opacity: 0.9;
}

input,
select,
textarea {
  vertical-align: middle;
  margin: 0;
  padding: 0;
  outline: 0;
}

textarea {
  resize: none;
}

.row {
  margin-bottom: 0;
}

.col-xs-12 {
  padding: 0;
}

i {
  color: inherit;
  font-style: normal;
  font-size: inherit;
}

.scrollTop {
  position: fixed;
  right: 0;
  bottom: 50px;
  z-index: 9;
  width: 40px;
  height: 40px;
  border: 0;
  padding: 0;
  background-color: #00c6bb;
  transition: all 0.3s;
  opacity: 0;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.scrollTop:hover {
  background-color: #002a4d;
  transition: all 0.3s;
}

.scrollTop.active {
  opacity: 1;
  transition: all 0.3s;
  right: 50px;
}

.scrollTop svg {
  fill: #fff;
}

.menu-item-has-children {
  position: relative;
}

.menu-item-has-children .sub-menu {
  position: absolute;
  right: 0;
  left: auto;
  top: 120%;
  opacity: 0;
  visibility: hidden;
  display: block;
  transition: all 0.3s;
  min-width: 200px;
  border-radius: 5px;
  background-color: #ffffff;
  z-index: 11;
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
}

.menu-item-has-children:hover .sub-menu {
  opacity: 1;
  visibility: visible;
  top: 100%;
  transition: all 0.3s;
}

.menu-item-has-children .sub-menu li {
  display: block !important;
  margin: 0 !important;
}

.menu-item-has-children .sub-menu li:after {
  display: none;
}

.menu-item-has-children .sub-menu li a {
  display: block;
  padding: 10px;
  font-weight: normal;
  font-size: 14px;
  color: #7d7d7d;
  border-radius: 0;
  border-bottom: 1px solid #f1f1f1;
  position: relative;
  transition: all 0.3s;
}

.menu-item-has-children .sub-menu li a:before {
  display: none;
}

.menu-item-has-children .sub-menu li:hover > a {
  color: #0065ca;
  transition: all 0.3s;
  padding: 10px 15px;
}

.menu-item-has-children:after {
  content: "";
  display: inline-block;
  margin-right: 5px;
  transition: all 0.3s;
  font-weight: 900;
  vertical-align: middle;
  font-size: 11px;
}

.menu-item-has-children:hover:after {
  transform: rotate(-180deg);
  transition: all 0.3s;
}

html,
body,
#__next,
.wrap {
  height: 100%;
}

.main-head {
  border-bottom: 1px solid #f2f2f2;
  position: fixed;
  width: 100%;
  background-color: #fff;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 99;
  transition: all 0.3s;
  flex-wrap: wrap;
}

.burgerBtn {
  margin-left: 25px;
  display: flex;
  align-items: center;
  column-gap: 15px;
}

.burgerBtn .toggleSidebar {
  border: 0;
  background: transparent;
  padding: 0;
  width: 46px;
  height: 46px;
  line-height: 46px;
  outline: none;
}

.burgerBtn .btn {
  border-radius: 5px;
  font-size: 14px;
  height: 45px;
  line-height: 45px;
}

.searchWrap .form-group {
  position: relative;
  margin: 0;
}

.form-control {
  height: 46px;
  background-color: #f9f9f9;
  border-radius: 10px;
  padding: 0 20px;
  border: 1px solid #f9f9f9;
  box-shadow: none !important;
  transition: all 0.3s;
  font-size: 14px;
  text-transform: none;
}

.form-control:focus {
  transition: all 0.3s;
  border-color: #dfdfdf;
}

.searchWrap .form-group button {
  position: absolute;
  left: 5px;
  top: 5px;
  width: 41px;
  height: 36px;
  border: 0;
  background: #f9f9f9;
  padding: 0;
  border-radius: 10px;
}

.timeWrap {
  display: flex;
  align-items: center;
}

.timeWrap span {
  font-size: 17px;
  width: 57px;
  height: 57px;
  line-height: 57px;
  font-family: "Bahij-semibold";
  direction: ltr;
  background-color: #f8f8f8;
  border-radius: 100%;
  margin: 0 5px;
  text-align: center;
}

.authWrap .au-img {
  width: 62px;
  height: 62px;
  border-radius: 100%;
  overflow: hidden;
}

.authWrap .au-img img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.authWrap li .au-flow {
  display: flex;
  align-items: center;
}

.authWrap .dropdown-menu {
  min-width: 210px;
}

.authWrap .dropdown-toggle {
  display: flex;
  height: auto;
  line-height: normal;
}

.authWrap .dropdown-toggle svg {
  margin-top: 17px;
}

.authWrap .au-data {
  padding: 0 12px;
  text-align: initial;
}

.authWrap .au-data h4 {
  font-size: 14px;
  font-family: "Bahij-bold";
  text-transform: capitalize;
}

.authWrap .au-data p {
  color: #8f8f8f;
  font-size: 12px;
}

.form-control::placeholder {
  color: #8f9bba;
  text-transform: inherit;
}

.authWrap li.menu-item-has-children {
  display: flex;
  align-items: center;
}

.noificationWrap .menu-item-has-children {
  display: flex;
}

.noificationWrap .menu-item-has-children .dropdown-toggle span,
.noificationWrap .menu-item-has-children > span {
  width: 57px;
  height: 57px;
  background-color: #f4f7fe;
  display: block;
  line-height: 57px;
  border-radius: 100%;
  text-align: center;
  position: relative;
}

.noificationWrap .dropdown-menu {
  min-width: 350px;
}

.badgo {
  position: absolute;
  right: 0;
  top: 0;
  font-style: normal;
  width: 26px;
  height: 26px;
  background-color: #2a88f3;
  line-height: 26px;
  color: #fff;
  border-radius: 100%;
  font-family: "Bahij-bold";
  font-size: 14px;
}

.main-sidebar {
  width: 292px;
  position: fixed;
  height: 100%;
  top: 0;
  background-color: #fff;
  right: 0;
  z-index: 100;
  transition: all 0.3s;
}

.content-wrap {
  min-height: 100%;
  background-color: #f9f9f9;
  padding: 44px;
  margin: 116px 292px 0 0;
  transition: all 0.3s;
}

.main-sidebar .logo {
  padding: 36px 38px;
  border-bottom: 1px solid #f2f2f2;
  display: flex;
  align-items: center;
}

.logo .logo-info {
  padding: 0 12px;
}

.main-sidebar .side-nav {
  padding: 40px 23px 40px 10px;
  height: calc(100% - 250px);
}

.main-sidebar .side-nav ul {
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
  padding: 0 0 0 15px;
}

.main-sidebar .side-nav ul li a span,
.main-sidebar .side-actions a span {
  color: inherit;
  font-size: inherit;
  transition: all 0.3s;
}

.main-sidebar .side-nav ul li a,
.main-sidebar .side-actions a {
  display: flex;
  align-items: center;
  padding: 9px 12px;
  border-radius: 3px;
  transition: all 0.3s;
  color: #a3aed0;
  font-size: 13px;
}

.main-sidebar .side-nav ul li a svg,
.main-sidebar .side-actions a svg {
  margin: 0 0 0 13px;
}

.main-sidebar .side-nav ul li a.active {
  background: linear-gradient(260.02deg, #395cf5 -21.44%, #20a9f2 117.29%);
  transition: all 0.3s;
  color: #fff;
}

.main-sidebar .side-nav ul li a.active svg {
  filter: brightness(0) invert(1);
  transition: all 0.3s;
}

.main-sidebar .side-nav ul li a ~ .children {
  max-height: 0;
  transition: max-height 300ms ease, background-color 300ms linear;
}

.main-sidebar .side-nav ul li a.active ~ .children {
  background: #fff;
  transition: max-height 300ms ease, background-color 300ms linear;
  max-height: inherit;
  cursor: pointer;
}

.main-sidebar .side-nav ul li a.active ~ .children > li > a {
  color: #929dbe;
  margin: 0;
}

.main-sidebar .side-nav ul li a.active ~ .children > li > .active-child,
.main-sidebar .side-nav ul li a.active ~ .children > li > a:hover {
  color: #2b87f3;
}

.main-sidebar .side-nav ul li a.active ~ .children > li > a:hover > span:before,
.main-sidebar .side-nav ul li a.active ~ .children .active-child span:before {
  background-color: #2b87f3 !important;
}

.main-sidebar .side-nav .side-nav-child {
  position: relative;
  margin-bottom: 12px;
}

.main-sidebar .side-nav .side-nav-child .arrow {
  position: absolute;
  transform: scale(1.3);
  cursor: pointer;
  left: 15px;
  top: 10px;
}

.main-sidebar .side-nav ul li a.active ~ .children > li > a > span {
  position: relative;
  margin-right: 40px;
  font-family: "Bahij-light";
  font-size: 14px;
  font-weight: 200;
  line-height: 22px;
  letter-spacing: 0em;
}

.main-sidebar .side-nav ul li a.active ~ .children > li > a > span:before {
  content: "";
  position: absolute;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  right: -15px;
  top: 7px;
  background-color: #929dbe;
}

.main-sidebar .side-nav .side-nav-child > span {
  display: flex;
  align-items: center;
  padding: 9px 12px;
  border-radius: 3px;
  transition: all 0.3s;
  color: #a3aed0;
  column-gap: 13px;
  cursor: pointer;
  font-size: 13px;
}

.main-sidebar .side-nav ul.children {
  max-height: 0;
  overflow: hidden;
  transition: 0.7s;
  background-color: #f7f7f7;
  border-radius: 30px;
  margin-top: 10px;
}

.main-sidebar .side-nav ul.children.active {
  max-height: 500px;
  padding: 10px 20px;
}

.main-sidebar .side-actions {
  padding: 23px 23px 0;
}

.main-sidebar .side-actions .themetoggle {
  margin-bottom: 30px;
  width: 60px;
  height: 30px;
  border-radius: 50px;
  border: 0.5px solid #a3aed0;
  transition: all 0.3s;
  position: relative;
  background-color: #fff;
}

.main-sidebar .side-actions .themetoggle i {
  width: 20px;
  height: 20px;
  position: absolute;
  background-color: #2696f3;
  border-radius: 100%;
  line-height: 20px;
  top: 4px;
  left: 5px;
  transition: all 0.3s;
}

.main-sidebar .side-nav ul li a.active-child {
  color: #336cf4;
}

.main-sidebar .side-nav ul li a {
  position: relative;
}

.main-sidebar .side-nav ul li a::before {
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  width: 4px;
  height: 4px;
  background-color: #356af5;
  border-radius: 100%;
  transform: translateY(-50%);
  opacity: 0;
  transition: 0.3s;
}

.main-sidebar .side-nav ul li a.active-child::before {
  opacity: 1;
}

.main-sidebar .side-actions .themetoggle i svg path {
  transition: all 0.3s;
}

.main-sidebar .side-actions .themetoggle.fire {
  background-color: #2696f3;
  border-color: #2696f3;
  transition: all 0.3s;
}

.main-sidebar .side-actions .themetoggle.fire i {
  transition: all 0.3s;
  left: calc(100% - 25px);
  background-color: #fff;
  transform: rotate(360deg);
}

.main-sidebar .side-actions .themetoggle.fire i svg path {
  fill: #2696f3;
  transition: all 0.3s;
}

.main-sidebar .side-nav ul::-webkit-scrollbar,
.employees-list::-webkit-scrollbar,
.noificationWrap .dropdown ul::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.main-sidebar .side-nav ul::-webkit-scrollbar-track,
.employees-list::-webkit-scrollbar-track,
.noificationWrap .dropdown ul::-webkit-scrollbar-track {
  background-color: #f7f7f7;
  border-radius: 30px;
}

.main-sidebar .side-nav ul::-webkit-scrollbar-thumb,
.employees-list::-webkit-scrollbar-thumb,
.noificationWrap .dropdown ul::-webkit-scrollbar-thumb {
  background-color: #239df2;
  border-radius: 30px;
}

.card {
  border-radius: 15px;
  border-color: #fff;
  padding: 20px;
  margin-bottom: 30px;
  transition: all 0.3s;
}

.topCards .card {
  text-align: center;
}

.topCards .card i {
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 13px;
  background-color: #f4f7fe;
  border-radius: 100%;
}

.topCards .card h4 {
  font-size: 14px;
  font-family: "Bahij-semibold";
}

.topCards .card span {
  color: #a3aed0;
  font-size: 12px;
  margin-top: 5px;
}

.card:hover {
  box-shadow: 0 0 45px rgb(0 0 0 / 5%);
  transition: all 0.3s;
}

.card-blue-bg {
  background: linear-gradient(135deg, #2697f3 0%, #3271f4 100%);
}

.card-green-bg {
  background: linear-gradient(135deg, #2cc156 0%, #71d68e 100%);
}

.card-red-bg {
  background: linear-gradient(135deg, #868cff 0%, #f89b99 0.01%, #f46d6a 100%);
}

.projects-blocks .card {
  flex-wrap: wrap;
  position: relative;
  flex-direction: row;
}

.projects-blocks .card:before {
  content: "";
  position: absolute;
  width: 35%;
  height: 100%;
  left: 0;
  top: 0;
  background: linear-gradient(
    186.12deg,
    #ffffff -9.77%,
    #ffffff -9.76%,
    rgba(255, 255, 255, 0) 88.02%
  );
  opacity: 0.2;
  -webkit-clip-path: polygon(0 0, 70% 0, 100% 100%, 0% 100%);
  clip-path: polygon(0 0, 70% 0, 100% 100%, 0% 100%);
  border-radius: 15px;
}

.projects-blocks .card .b-info {
  text-align: initial;
  flex-grow: 1;
}

.projects-blocks .card .b-info h4 {
  color: #fff;
  font-size: 13px;
}

.projects-blocks .card .b-info p {
  font-size: 37px;
  font-family: "Bahij-bold";
  color: #fff;
}

.projects-blocks .card > svg {
  position: absolute;
  left: 20px;
  bottom: 26px;
}

.dropdown-toggle {
  border: 0;
  background: transparent !important;
  padding: 0;
  box-shadow: none !important;
}

.dropdown-toggle:after {
  display: none;
}

.dropdown-menu {
  border-radius: 10px;
  border: 1px solid #f7f7f7;
  box-shadow: 0 3px 20px rgb(0 0 0 / 8%);
  padding: 0;
  overflow: hidden;
  text-align: initial;
}

.dropdown-menu a {
  transition: all 0.3s;
  padding: 8px 15px;
  font-size: 14px;
}

.dropdown-menu a:hover {
  transition: all 0.3s;
}

.reports-blocks .card h4 {
  font-size: 17px;
  font-family: "Bahij-semibold";
  margin-bottom: 35px;
}

.recharts-legend-wrapper {
  bottom: auto !important;
  top: -60px;
  left: 0 !important;
}

.recharts-default-legend {
  text-align: left !important;
}

.recharts-legend-item svg {
  width: 17px;
  height: 17px;
  border-radius: 5px;
  margin: 0 9px 0 0 !important;
}

.recharts-legend-item svg path {
  width: 100%;
  height: 100%;
}

.recharts-legend-item {
  direction: ltr;
  align-items: center;
  display: inline-flex !important;
  margin: 0 30px 0 0 !important;
}

.recharts-legend-item-text {
  font-size: 13px;
  color: #4f4f4f !important;
  text-transform: none;
}

.pieChart .recharts-legend-wrapper {
  left: auto !important;
  right: 0 !important;
  top: 50% !important;
  bottom: auto !important;
  transform: translateY(-50%);
  width: auto !important;
}

.pieChart .recharts-default-legend {
  text-align: right !important;
}

.pieChart .recharts-legend-item {
  direction: rtl !important;
  display: flex !important;
  margin: 0 0 11px !important;
}

.pieChart .recharts-legend-item svg {
  margin: 0 0 0 9px !important;
}

.pieChart .recharts-legend-item:last-child {
  margin: 0 !important;
}

.card-head {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 35px;
}

.card-head h4 {
  font-size: 20px;
  font-family: "Bahij-semibold";
  margin-bottom: 5px;
}

.card-head p {
  color: #a3aed0;
  font-size: 13px;
}

.card-head path {
  fill: #8e8ea1;
}

.project-item {
  border-radius: 15px;
  padding: 20px;
  position: relative;
  margin-bottom: 24px;
}

.project-item .b-action {
  text-align: left;
  margin-bottom: 15px;
}

.project-item:before {
  content: "";
  position: absolute;
  width: 35%;
  height: 100%;
  left: 0;
  top: 0;
  background: linear-gradient(
    186.12deg,
    #ffffff -9.77%,
    #ffffff -9.76%,
    rgba(255, 255, 255, 0) 88.02%
  );
  opacity: 0.2;
  -webkit-clip-path: polygon(0 0, 70% 0, 100% 100%, 0% 100%);
  clip-path: polygon(0 0, 70% 0, 100% 100%, 0% 100%);
  border-radius: 15px;
}

.project-item .i-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.project-item .i-head a {
  color: #fff;
  font-family: "Bahij-semibold";
  font-size: 17px;
  max-width: 70%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.project-item .i-head span {
  font-size: 13px;
  color: #fff;
}

.project-item .i-body {
  margin-bottom: 10px;
}

.project-item .i-body h5 {
  font-size: 15px;
  color: #fff;
  margin-bottom: 5px;
}

.project-item .i-body p {
  color: #fff;
  font-size: 13px;
  font-family: "Bahij-light";
}

.project-item .i-footer ul {
  display: flex;
  align-items: center;
}

.project-item .i-footer ul li {
  color: #fff;
  margin-left: 13px;
  font-size: 13px;
  display: inline-flex;
  align-items: center;
}

.project-item .i-footer ul li svg {
  margin-left: 7px;
}

.customers-list {
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 193px;
}

.customers-list::-webkit-scrollbar {
  width: 10px;
}

.customers-list::-webkit-scrollbar-track {
  background: #f4f7fe;
}

.customers-list::-webkit-scrollbar-thumb {
  background: #a3aed0;
  border-radius: 10px;
}

.customers-list .c-item {
  border-left: 1px solid #f2f2f2;
  text-align: center;
  margin-bottom: 30px;
}

.customers-list .col-xl-6:nth-of-type(even) .c-item {
  border: 0;
}

.customers-list .c-item .i-img {
  width: 104px;
  height: 104px;
  background-color: #f4f7fe;
  border-radius: 100%;
  margin: 0 auto 17px;
}

.customers-list .c-item .i-img img {
  border-radius: 50%;
}

.customers-list .c-item .i-data h4 {
  margin-bottom: 6px;
  font-size: 17px;
}

.customers-list .c-item .i-data p {
  color: #a3aed0;
  font-size: 13px;
}

.customers-list > :last-child .c-item,
.customers-list > :nth-last-child(2) .c-item,
.employees-list .employee-item:last-child {
  margin-bottom: 0;
}

.employees-list .employee-item {
  margin-bottom: 30px;
  display: flex;
  align-items: center;
}

.employees-list .employee-item .i-img {
  width: 36px;
  height: 36px;
  border-radius: 100%;
}

.employees-list .employee-item img {
  border-radius: 50%;
  object-fit: contain;
}

.employees-list .employee-item .i-info {
  padding: 0 15px;
}

.employees-list .employee-item .i-info h4 {
  font-size: 15px;
  margin-bottom: 2px;
}

.employees-list .employee-item .i-info p {
  font-size: 13px;
  color: #a3aed0;
}

.reports-blocks .card {
  border-color: #ebebeb;
}

.employees-list {
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 193px;
}

.employees-list::-webkit-scrollbar {
  width: 10px;
}

.employees-list::-webkit-scrollbar-track {
  background: #f4f7fe;
}

.employees-list::-webkit-scrollbar-thumb {
  background: #a3aed0;
  border-radius: 10px;
}

.sidebarToggled .main-head .head-wrp {
  padding-right: 125px;
  transition: all 0.3s;
}

.sidebarToggled .main-sidebar {
  width: 125px;
  transition: all 0.3s;
}

.sidebarToggled .main-sidebar .logo .logo-info {
  opacity: 0;
  transition: all 0.3s;
  position: absolute;
}

.sidebarToggled .main-sidebar .side-nav ul li a span,
.sidebarToggled .main-sidebar .side-actions a span {
  transition: all 0.3s;
  opacity: 0;
  position: absolute;
}

.sidebarToggled .main-sidebar .side-nav ul li a,
.sidebarToggled .main-sidebar .side-actions a {
  text-align: center;
  justify-content: center;
  transition: all 0.3s;
  overflow: hidden;
}

.sidebarToggled .main-sidebar .side-nav ul li a svg,
.sidebarToggled .main-sidebar .side-actions a svg {
  margin: 0;
  transition: all 0.3s;
}

.sidebarToggled .content-wrap {
  margin-right: 125px;
  transition: all 0.3s;
}

.sidebarToggled .main-sidebar .side-actions {
  text-align: center;
}

.logo .inner,
.logo .inner a {
  overflow: hidden;
  width: 120px;
  display: flex;
  align-items: center;
}

.head-wrp {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 29px 292px 29px 38px;
  transition: all 0.3s;
}

.main-head .logo {
  display: none;
  justify-content: center;
  padding: 18px;
  background-color: #f9f9f9;
  width: 100%;
}

.card-body {
  padding: 0;
}

.overlay-s {
  position: fixed;
  z-index: 99;
  background-color: rgb(4 13 40 / 55%);
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
}

.show-btn,
.edit-btn,
.delete-btn {
  width: 35px !important;
  height: 35px !important;
  text-align: center;
  line-height: 35px;
  border-radius: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 35px;
}

.show-btn svg,
.edit-btn svg,
.delete-btn svg {
  width: 15px;
  height: auto;
}

.show-btn {
  background-color: #259c46;
  margin-left: 10px;
}

.edit-btn {
  background-color: #346cf4;
}

.delete-btn {
  background-color: #ff2625;
}

.btn {
  height: 38px;
  padding: 0 25px;
  line-height: 38px;
  border-radius: 10px;
  font-size: 15px;
  position: relative;
  box-shadow: none !important;
  overflow: hidden;
}

.btn:before {
  content: "";
  position: absolute;
  display: block;
  background: var(--ripple-background, white);
  border-radius: 50%;
  pointer-events: none;
  top: calc(var(--y) * 1px);
  left: calc(var(--x) * 1px);
  width: calc(var(--d) * 1px);
  height: calc(var(--d) * 1px);
  opacity: calc(var(--o, 1) * var(--ripple-opacity, 0.3));
  transition: calc(var(--t, 0) * var(--ripple-duration, 600ms))
    var(--ripple-easing, linear);
  transform: translate(-50%, -50%) scale(var(--s, 1));
  transform-origin: center;
}

.btn.btn-blue {
  background-color: #346cf4;
  color: #fff;
}

.btn.btn-green {
  background-color: #53cc75;
  color: #fff;
}

.btn.btn-red {
  background-color: #ff2625;
  color: #fff;
}

.btn.btn-purple {
  background-color: #92a5f9;
  color: #fff;
}

.btn.btn-big {
  height: 67px;
  line-height: 67px;
  width: 100%;
}

.btn.btn-outline {
  border: 1.31429px solid rgba(86, 103, 137, 0.26);
  background-color: transparent;
  color: #000;
}

.btn.btn-big svg {
  margin-left: 24px;
}

.control-wrp .card {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

.control-wrp .card h5 {
  font-size: 14px;
  display: flex;
  align-items: center;
}

.control-wrp .card span svg {
  margin-left: 21px;
}

.control-wrp .card h5 img {
  width: 25px;
  height: 25px;
  margin-left: 10px;
  object-fit: contain;
}

.card-inner .c-img img {
  object-fit: scale-down;
}

.control-wrp .card .btn {
  margin-right: 15px;
}

@keyframes click-wave {
  0% {
    height: 26px;
    width: 26px;
    opacity: 0.35;
    position: relative;
  }

  100% {
    height: 35px;
    width: 35px;
    opacity: 0;
    margin: -5px;
  }
}

.form-group .download-file {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 200px;
  height: 100px;
  background-color: #e9ecef;
  border-radius: 8px;
  padding: 8px 14px;
  transition: all ease 0.45s;
}

.form-group .download-file:hover {
  transform: scale(0.9);
  box-shadow: 0px 3.55168px 9.47116px rgba(0, 0, 0, 0.15),
    0px 3.55168px 1.18389px rgba(0, 0, 0, 0.06);
}

.form-group .download-file svg {
  position: inherit;
  top: unset;
  right: unset;
  transform: unset;
  transform: scale(1.3);
}

input[type="checkbox"],
input[type="radio"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  position: relative;
  height: 26px;
  width: 26px;
  transition: all 0.15s ease-out 0s;
  background: #fff;
  border: none;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  outline: none;
  z-index: 1;
  border-radius: 4px;
  border: 1px solid #dfdfdf;
  box-shadow: none !important;
}

input[type="checkbox"]:checked,
input[type="radio"]:checked {
  background: #fff;
  border-color: #f1f1f1;
}

input[type="checkbox"]:checked::before,
input[type="radio"]:checked::before {
  height: 26px;
  width: 26px;
  position: absolute;
  content: "✔";
  display: inline-block;
  font-size: 15px;
  text-align: center;
  line-height: 26px;
  color: #346cf4;
}

input[type="checkbox"]:checked::after,
input[type="radio"]:checked::after {
  -webkit-animation: click-wave 0.65s;
  -moz-animation: click-wave 0.65s;
  animation: click-wave 0.65s;
  background: #346cf4;
  content: "";
  display: block;
  position: relative;
  border-radius: 4px;
  z-index: 2;
}

input[type="radio"]::after,
input[type="radio"] {
  border-radius: 100% !important;
}

input[type="radio"]:checked::before {
  content: "•";
  font-size: 42px;
}

.noificationWrap .menu-item-has-children .btn {
  height: auto;
  line-height: normal;
  padding: 0;
}

.table-wrap .edit-btn {
  margin-left: 10px;
}

.rdt_Pagination {
  justify-content: space-between !important;
  padding: 30px 0 !important;
  border: 0 !important;
}

.rdt_Pagination span {
  margin: 0;
}

.rdt_Pagination > div {
  border: 1px solid #346cf4;
  border-radius: 50px;
}

.table-wrap .card-body {
  border-top: 1px solid #f4f7fe;
}

.rdt_Pagination > div button svg {
  fill: #346cf4;
}

.rdt_TableCell {
  padding: 19px !important;
  border: 0;
}

.rdt_TableRow,
.rdt_TableHeadRow {
  border-bottom: 2px solid #f2f2f2 !important;
}

.rdt_TableRow:nth-of-type(odd) {
  background-color: #f6f6f6;
}

.rdt_TableRow:last-child {
  border: 0 !important;
}

.rdt_TableCol {
  padding: 19px 0;
}

.rdt_TableCell:first-child {
  padding: 19px 0;
}

.table-wrap .card-body header {
  min-height: auto !important;
  padding: 0 !important;
}

.filter-wrp {
  margin-top: -100px;
}

.filter-wrp .form-group {
  position: relative;
}

.filter-wrp .form-group svg {
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
  right: auto;
}

.table-pager {
  padding: 40px 0;
  display: flex;
  justify-content: flex-end;
}

.table-pager .pagination {
  justify-content: end;
  border: 1px solid #346cf4;
  border-radius: 50px;
  padding: 5px;
  overflow: hidden;
}

.table-pager .pagination .page-link {
  border: 0 !important;
  color: #346cf4 !important;
  width: 37px;
  height: 37px;
  border-radius: 100%;
  background: transparent;
  font-size: 14px;
  line-height: 37px;
  padding: 0;
  font-size: 18px;
  box-shadow: none;
}

.table-pager .pagination .active .page-link {
  color: #fff !important;
  background: linear-gradient(153.81deg, #395af5 16.48%, #21a7f2 153.73%);
}

.table-pager .pagination .page-link:disabled {
  color: #bdbdbd !important;
}

.table-pager .pagination .page-link.prev-link,
.table-pager .pagination .page-link.nxt-link {
  border-radius: 0;
  width: auto;
  padding: 0 20px;
  font-size: 14px;
}

.table-select select {
  border: 1px solid #346cf4;
  border-radius: 50px;
  padding: 5px;
  overflow: hidden;
  background: #fff;
  color: #395af5;
}

.form-check-input:checked[type="checkbox"] {
  background-image: none !important;
}

.dropdown-toggle {
  padding: 0;
}

.form-control.form-outline {
  border-color: #efefef;
  background-color: transparent;
}

.form-group.required h5:after {
  content: "*";
  color: #ff0303;
  margin: 0 3px;
  font-size: 17px;
  line-height: 0;
}

.form-group.has-error .form-control {
  border-color: #ff0303;
  color: #ff0303;
}

.form-group {
  margin-bottom: 17px;
}

.form-group h5 {
  font-size: 14px;
  margin-bottom: 17px;
}

.form-group svg {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}

.form-group > div {
  position: relative;
}

.form-group svg + .form-control {
  padding: 0 45px;
}

textarea.form-control {
  height: 211px;
  padding: 15px 20px;
}

.form-group strong {
  position: absolute;
  left: 20px;
  font-weight: 300;
  color: #545454;
  font-size: 13px;
  top: 50%;
  transform: translateY(-50%);
}

.form-group .btn {
  height: 46px;
  line-height: 46px;
  width: 100%;
}

.flatpickr-day {
  font-size: 13px !important;
}

.flatpickr-months .flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month {
  padding: 2px 10px !important;
  line-height: 34px !important;
}

.flatpickr-current-month {
  padding: 7px 0 !important;
  font-size: 15px !important;
}

.flatpickr-current-month .numInputWrapper {
  font-size: 15px !important;
}

.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
  background: linear-gradient(
    260.02deg,
    #395cf5 -21.44%,
    #20a9f2 117.29%
  ) !important;
}

.searchWrap .form-control {
  min-width: 341px;
}

span.flatpickr-weekday {
  font-size: 13px;
  font-family: "Bahij-bold";
  color: #000;
}

.modal-content {
  border-radius: 15px;
  border: 0;
}

.modal-header {
  padding: 35px;
  border: 0;
}

.modal-header .btn-close {
  opacity: 1 !important;
  color: #676767 !important;
  box-shadow: none !important;
}

.modal-body {
  padding: 35px;
}

.modal-wrp {
  text-align: center;
}

.modal-wrp .modal-icon {
  width: 117px;
  height: 117px;
  margin: 0 auto 40px;
  position: relative;
}

.modal-wrp h4 {
  font-size: 24px;
  margin-bottom: 14px;
  font-family: "Bahij-semibold";
  color: #000;
}

.modal-wrp p {
  color: #3f3f3f;
  font-size: 12px;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 700px;
  }
}

.modal-btns {
  display: flex;
  align-items: center;
  margin-top: 40px;
  justify-content: center;
}

.modal-btns .btn {
  margin: 0 6px;
  height: 55px;
  padding: 0 50px;
  font-size: 18px;
  line-height: 55px;
}

.modal-open {
  padding: 0 !important;
}

.knobs,
.layer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.switcher {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 60px;
  height: 36px;
  overflow: hidden;
}

.switcher,
.switcher .layer {
  border-radius: 100px;
}

.switcher .switcher-input {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 3;
}

.knobs {
  z-index: 2;
}

.layer {
  width: 100%;
  background-color: #f5f7ff;
  transition: 0.3s ease all;
  z-index: 1;
}

.switcher .knobs:before {
  content: "";
  position: absolute;
  top: 3px;
  left: 2px;
  width: 31px;
  height: 30px;
  box-shadow: 0px 3.55168px 9.47116px rgba(0, 0, 0, 0.15),
    0px 3.55168px 1.18389px rgba(0, 0, 0, 0.06);
  background-color: #fff;
  border-radius: 50%;
  transition: 0.3s ease all, left 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15);
}

.switcher .switcher-input:active + .knobs:before {
  width: 46px;
  border-radius: 100px;
}

.switcher .switcher-input:checked:active + .knobs:before {
  margin-left: -26px;
}

.switcher .switcher-input:checked + .knobs:before {
  content: "";
  left: 26px;
}

.switcher .switcher-input:checked ~ .layer {
  background-color: #346cf4;
}

.card-inner {
  position: relative;
  min-height: 155px;
}

.card-inner .checker {
  position: absolute;
  left: 0;
  top: 0;
}

.card-inner .checker input[type="checkbox"] {
  background-color: #f5f7ff;
  border: 0;
}

.switcher .switcher-input:before,
.switcher .switcher-input:after {
  display: none !important;
}

.payment-name {
  position: absolute;
  right: 0;
  bottom: 0;
  font-family: "Bahij-bold";
  font-size: 17px;
}

.form-group .show-pass {
  position: absolute;
  left: 10px;
  top: 0;
  border: 0;
  background-color: transparent;
  height: 46px;
  line-height: 46px;
  padding: 0 10px;
}

.form-group .show-pass svg {
  position: relative;
  right: auto;
  left: auto;
  transform: none;
  top: auto;
}

.image-uploader {
  position: relative;
  width: 160px;
  height: 160px;
  background-color: #346cf4;
  border-radius: 11px;
  margin-bottom: 45px;
  z-index: 1;
}

.img-pat {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
  z-index: 1;
}

.img-pat img {
  object-fit: contain;
}

.image-uploader label {
  cursor: pointer;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 2;
}

.image-uploader input {
  position: absolute;
  width: 100%;
  height: 100%;
  right: 0;
  top: 0;
  cursor: pointer;
  display: none;
}

.removeImg {
  position: absolute;
  left: -10px;
  top: -10px;
  padding: 0;
  border: 1px solid #ff2625;
  height: 25px;
  width: 25px;
  background: #ff2625;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  box-shadow: 0 0 0 3px #fff;
}

.removeImg svg {
  fill: #fff;
  position: relative;
  right: auto;
  left: auto;
  transform: none;
  top: auto;
}

.image-uploader label div {
  overflow: hidden;
  border-radius: 10px;
  background-color: #346cf4;
  width: 100%;
  height: 100%;
}

.image-uploader label div img {
  object-fit: cover;
}

.status {
  position: absolute;
  width: 7px;
  height: 7px;
  background-color: #000;
  left: 0;
  border-radius: 100%;
  margin: 0 10px;
  top: 50%;
  transform: translateY(-50%);
}

.status.green {
  background-color: #53cc75;
}

.status.red {
  background-color: #f68583;
}

.status.blue {
  background-color: #346cf4;
}

.form-uploader {
  width: 100%;
  height: 100px;
  border-radius: 10px;
  padding: 0 20px;
  border: 2px dashed #efefef;
  box-shadow: none !important;
  transition: all 0.3s;
  font-size: 14px;
  text-transform: none;
  line-height: 46px;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.form-uploader svg {
  position: absolute;
  left: 20px;
  right: auto;
}

.form-uploader input {
  position: absolute;
  width: 100%;
  height: 100%;
  right: 0;
  top: 0;
  cursor: pointer;
  padding: 0 20px;
}

.form-uploader span {
  display: block;
  background: #fff;
  z-index: 1;
  position: absolute;
  width: calc(100% - 80px);
  right: 20px;
  top: 0;
  height: 44px;
  font-size: 14px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

input[type="file"]::-webkit-file-upload-button {
  display: none;
}

.images-wrp {
  margin: 20px 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.images-wrp .img-holder {
  border: 1px solid #dfdfdf;
  border-radius: 10px;
  overflow: hidden;
  margin: 7px;
  min-width: 96px;
  width: 96px;
}

.activate {
  margin: 14px 0 60px;
}

.employee-wrap .form-group {
  margin-bottom: 30px;
}

.activate span {
  color: #bbbbbb;
  font-size: 13px;
}

.activate h5 {
  margin-bottom: 0;
  padding-top: 10px;
}

.activate .switcher {
  bottom: auto;
  top: 0;
  position: relative;
}

.activate .switcher .switcher-input:checked ~ .layer {
  background-color: #49ca6e;
}

.searchWrap .form-group button svg {
  position: relative;
  right: auto;
  left: auto;
  top: auto;
  transform: none;
}

.card-head.with-icon > div {
  display: flex;
  align-items: center;
}

.card-head.with-icon i {
  width: 37px;
  height: 37px;
  background-color: #f9f9f9;
  border-radius: 100%;
  text-align: center;
  line-height: 37px;
  margin-left: 19px;
}

.card-head.with-icon > div {
  flex-wrap: wrap;
  align-items: flex-start;
}

.card-head.with-icon h4 {
  width: 100%;
  font-size: 24px;
  color: #000;
}

.card-head.with-icon p {
  font-size: 12px;
}

.report-item {
  border-radius: 7px;
  background-color: #f7f7f7;
  margin-bottom: 24px;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  padding: 20px 35px;
  transition: all 0.3s;
  min-height: 140px;
}

.report-item:hover {
  transform: scale(0.97);
  transition: all 0.3s;
  box-shadow: 0 0 30px rgb(0 0 0 / 10%);
}

.report-item .overlay-icon {
  opacity: 0.05;
  position: absolute;
  left: 11px;
  bottom: -5px;
  filter: brightness(0) invert(1);
}

.report-item .r-img {
  filter: brightness(0) invert(1);
}

.report-item .r-data {
  padding: 0 17px;
}

.report-item .r-data h4 {
  font-size: 18px;
  font-family: "Bahij-semibold";
  margin-bottom: 8px;
  color: #fff;
}

.report-item .r-data p {
  color: #fff;
  font-size: 12px;
}

.itemTheme1 {
  background-color: #346bf4;
}

.itemTheme2 {
  background-color: #dc42cf;
}

.itemTheme3 {
  background-color: #ff4d99;
}

.itemTheme4 {
  background-color: #ff8669;
}

.itemTheme5 {
  background-color: #ffc353;
}

.itemTheme6 {
  background-color: #395cf5;
}

.report-item svg {
  position: absolute;
  left: 20px;
  top: 20px;
}

.customer-reports .card-head.with-icon i {
  background-color: #346bf4;
}

.customer-reports .card-head.with-icon i svg {
  filter: brightness(0) invert(1);
}

.customer-item {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  background-color: #f5f7ff;
  border-radius: 10px;
  padding: 17px 36px;
  transition: all 0.3s;
}

.customer-item:hover {
  background-color: #fff;
  box-shadow: 0 1px 10px rgb(0 0 0 / 10%);
  transition: all 0.3s;
}

.customer-item:last-of-type {
  margin: 0;
}

.customer-item:before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  width: 5px;
  height: 30px;
  background-color: #346cf4;
  border-radius: 15px 0 0 15px;
}

.customer-item > div {
  display: flex;
  align-items: center;
}

.customer-item .i-img {
  background-color: #fff;
  width: 50px;
  height: 50px;
  text-align: center;
  border-radius: 100%;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 33px;
}

.customer-item .i-img img {
  object-fit: contain;
}

.customer-item .i-data h4 {
  font-size: 16px;
  color: #000;
}

.customer-item .i-data p {
  color: #a3aed0;
  font-size: 13px;
}

.customer-item .i-action button {
  margin-right: 18px;
  border: 0;
  background: transparent;
  padding: 0;
}

.radios-shape-buttons label {
  width: 100%;
}

.radios-shape-buttons .form-group .btn {
  cursor: pointer;
  width: 100%;
  height: 60px;
  line-height: 60px;
  font-size: 14px;
  transition: all 0.3s;
}

.btn.btn-gray {
  background-color: #f5f7ff;
  color: #090e25;
}

.radios-shape-buttons label input {
  display: none;
}

.radios-shape-buttons label input:checked + span {
  background-color: #53cc75 !important;
  color: #fff !important;
  transition: all 0.3s;
}

.filter-wrp .form-group .form-control {
  min-width: 341px;
}

.employees-wrap .filter-wrp {
  margin: 0;
}

.employee-itm {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #f1f1f1;
  border-radius: 15px;
  padding: 20px;
  margin-bottom: 15px;
  position: relative;
}

.employee-itm .i-img {
  width: 40px;
  height: 40px;
  text-align: center;
  border-radius: 100%;
  overflow: hidden;
  margin: 0 0 0 15px;
}

.employee-itm .i-img img {
  object-fit: cover;
}

.employee-itm .i-data h4 {
  font-family: "Bahij-bold";
  font-size: 16px;
  margin-bottom: 3px;
}

.employee-itm .i-data p {
  font-size: 13px;
}

.customer-itm:before {
  background-color: #53cc75;
}

.employee-itm .emp-times span {
  font-size: 14px;
  margin-right: 20px;
  cursor: default;
  direction: ltr;
}

.employee-itm .emp-data {
  display: flex;
  align-items: center;
  position: relative;
}

.employee-itm:before {
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 5px;
  height: 30px;
  background-color: #346cf4;
  border-radius: 13px 0 0 13px;
}

.customer-itm[status] .emp-data:before {
  width: 9px;
  height: 9px;
  position: absolute;
  right: 0;
  bottom: 5px;
  content: "";
  border-radius: 100%;
  box-shadow: 0 0 0 2px #fff;
  z-index: 1;
  background-color: #ddd;
}

.customer-itm[status="active"] .emp-data:before {
  background-color: #01e50a;
}

.customer-itm[status="away"] .emp-data:before {
  background-color: #f91715;
}

.itemTheme7 {
  background-color: #f68987;
}

.itemTheme8 {
  background-color: #346cf4;
}

.permission-wrp .report-item {
  border-radius: 10px;
  min-height: 70px;
}

.permission-wrp .report-item svg {
  display: none;
}

.permission-wrp .card-body .row {
  justify-content: center;
}

.itemGray {
  background-color: #f5f7ff;
}

.bigCard {
  border-radius: 20px !important;
  min-height: 156px !important;
}

.itemGray .r-img {
  width: 62px;
  height: 62px;
  background-color: #346bf4;
  filter: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  padding: 15px;
}

.itemGray .r-img * {
  filter: brightness(0) invert(1);
}

.itemGray .r-data h4 {
  color: #000;
}

.itemGray .r-data p {
  color: #a3aed0;
}

.itemGray .overlay-icon {
  display: none;
}

.itemGray:hover {
  background-color: #fff;
}

.error-outer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 700px;
}

.error-outer h1 {
  font-size: 50px;
  display: inline-block;
  padding-right: 12px;
  animation: type 0.5s alternate infinite;
}

@keyframes type {
  from {
    box-shadow: inset -3px 0px 0px #888;
  }

  to {
    box-shadow: inset -3px 0px 0px transparent;
  }
}

.page_404 {
  padding: 40px 0;
  background: #fff;
  height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.page_404 .text-center {
  width: 100%;
}

.page_404 img {
  width: 100%;
}

.four_zero_four_bg {
  background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);
  height: 400px;
  background-position: center;
  background-repeat: no-repeat;
}

.four_zero_four_bg h1 {
  font-size: 80px;
}

.four_zero_four_bg h3 {
  font-size: 80px;
}

.link_404 {
  color: #fff !important;
  padding: 10px 20px;
  background: #39ac31;
  margin: 20px 0;
  display: inline-block;
}

.contant_box_404 {
  margin-top: -50px;
}

.noificationWrap li a {
  display: flex;
  align-items: center;
  padding: 15px;
  transition: all 0.3s;
}

.noificationWrap li a:hover {
  background-color: #f7f7f7;
  transition: all 0.3s;
}

.noificationWrap li {
  border-bottom: 1px solid #f7f7f7;
}

.noificationWrap .i-img {
  width: 40px;
  min-width: 40px;
  height: 40px;
  border-radius: 100%;
  overflow: hidden;
  margin-left: 15px;
}

.noificationWrap .i-img img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.noificationWrap .i-data p {
  font-size: 13px;
  overflow: hidden;
  max-height: 41px;
  color: #4c4c4c;
}

.noificationWrap li:last-of-type {
  border: 0;
}

.noificationWrap .dropdown ul {
  overflow: hidden;
  overflow-y: auto;
  margin: 10px;
  max-height: 300px;
  padding-left: 10px;
}

.pieChart .recharts-layer text {
  transform: translate(20px, 1px);
}

.recharts-yAxis .recharts-layer text {
  transform: translate(-20px, -5px);
}

.logo-icon {
  height: 48px;
}

.logo .logo-info {
  padding: 0;
  margin: 0 12px;
}

[data-animation] {
  position: relative;
  overflow: hidden;
}

.ripple {
  width: 2px;
  height: 2px;
  position: absolute;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.5);
  -webkit-animation: rippleEffect 0.5s ease-in-out;
  animation: rippleEffect 0.5s ease-in-out;
}

@-webkit-keyframes rippleEffect {
  0% {
    transform: scale(1);
  }

  100% {
    opacity: 0;
    transform: scale(var(--scale));
  }
}

@keyframes rippleEffect {
  0% {
    transform: scale(1);
  }

  100% {
    opacity: 0;
    transform: scale(var(--scale));
  }
}

.recharts-default-tooltip * {
  font-size: inherit;
  padding: 0 !important;
  text-transform: none;
  direction: ltr;
}

tspan,
.recharts-default-tooltip {
  font-size: 14px;
}

.form-check {
  padding-right: 0;
}

.images-wrp .img-holder img {
  object-fit: cover;
}

html,
body,
#root,
.main-wrapper {
  height: 100%;
}

.offset-lg-5 {
  margin-right: 0;
}

line {
  stroke: #eff3f9;
}

.recharts-cartesian-axis line {
  stroke: #ebebeb;
}

.recharts-rectangle.recharts-tooltip-cursor {
  fill: #ebebeb;
}

.noificationWrap li li {
  display: flex;
  align-items: center;
  padding: 15px;
  transition: all 0.3s;
}

.login-wrp {
  background-color: #346cf4;
  text-align: center;
  position: relative;
  padding: 200px 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-wrp:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 155px;
  background-image: url(../assets/images/vectors.png);
}

.login-wrp .login-logo {
  margin-bottom: 40px;
}

.login-wrp .login-logo img {
  width: 116px;
  object-fit: contain;
}

.login-wrp .login-data {
  margin-bottom: 35px;
}

.login-wrp .login-data h3 {
  color: #fff;
  font-family: "Bahij-bold";
  font-size: 26px;
  margin-bottom: 2px;
}

.login-wrp .login-data p {
  font-size: 18px;
  color: #fff;
  font-family: "Bahij-light";
}

.login-wrp .login-form {
  margin: 0 auto;
  min-width: 310px;
}

.login-wrp .login-form .form-group {
  position: relative;
}

.login-wrp .login-form .form-group .form-control {
  height: 60px;
  border-radius: 50px;
  text-align: right;
  text-transform: none;
  border: 0;
  padding: 0 58px 0 20px;
}

.login-wrp .login-form .form-group .form-control::placeholder {
  color: rgb(51 51 51 / 30%);
}

.login-wrp .login-form .form-group .btn {
  background-color: #2695f3;
  border-radius: 50px;
  height: 60px;
  line-height: 60px;
  padding: 0 20px;
  font-size: 14px;
  margin-top: 10px;
}

.login--inner {
  position: relative;
  z-index: 1;
}

.form-group.branches > h5 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: "Bahij-bold";
  font-size: 17px;
  margin-bottom: 20px;
}

.form-group.branches h5 .btn {
  width: auto;
  font-size: 14px;
  font-family: "Bahij-regular";
  height: 40px;
  line-height: 40px;
  border-radius: 5px;
  padding: 0 20px;
}

.form-group.branches {
  border-radius: 10px;
  padding: 20px;
  border: 1px solid #efefef;
}

.form-group.branches .form-control {
  background: #fff;
}

.add-multi-component {
  background-color: #f7f7f7;
  border-radius: 10px;
  padding: 15px 15px 0;
  border: 1px solid #efefef;
  margin: 0 0 20px;
  position: relative;
}

.add-multi-component:last-of-type {
  margin-bottom: 0;
}

.error-hint {
  color: #f00;
  font-size: 13px;
  margin-top: 5px;
}

.has-tooltip:before {
  content: attr(title);
  position: absolute;
  top: 20px;
  transform: translateX(-50%);
  left: 50%;
  padding: 5px 10px;
  background: #3e3e3e;
  color: #fff;
  text-align: center;
  border-radius: 50px;
  transition: all 0.3s;
  font-size: 12px;
  min-width: 100px;
  opacity: 0;
}

.bsgpsK div:first-child {
  overflow: visible !important;
  white-space: normal !important;
}

.has-tooltip {
  position: relative;
}

.has-tooltip:hover:before {
  top: -35px;
  transition: all 0.3s;
  opacity: 1;
}

.sidebarToggled .main-sidebar .side-nav ul li a ~ .children {
  max-height: 0;
  transition: max-height 300ms ease, background-color 300ms linear;
}

.branch-block {
  border-bottom: 1px solid #dfdfdf;
  padding: 15px 0;
}

.branch-block h5 {
  font-family: "Bahij-semibold";
  font-size: 16px;
  margin-bottom: 10px;
}

.branch-block ul li {
  font-family: "Bahij-light";
  font-size: 13px;
  margin-bottom: 5px;
}

.branch-block:last-of-type {
  border: 0;
  padding-bottom: 0;
}

.branch-block:first-of-type {
  padding-top: 0;
}

.branch-block ul li:last-child {
  margin: 0;
}

.rdt_TableCell div {
  width: 100%;
}

.rdt_TableRow:last-of-type {
  border: 0 !important;
}

[type="email"],
[type="number"],
[type="date"] {
  direction: inherit;
}

.store-logo {
  width: 70px;
  height: 70px;
  border-radius: 100%;
  object-fit: cover;
  min-width: 70px;
  border: 1px solid #dfdfdf;
  background-color: #fff;
  min-height: 70px;
  max-width: 70px;
  overflow: hidden;
  font-size: 12px;
  text-align: center;
}

.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 35px;
  border-width: 2px;
  border-radius: 10px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
}

.thumbs {
  display: flex;
  align-items: center;
  margin-top: 20px;
  column-gap: 20px;
  row-gap: 20px;
  flex-wrap: wrap;
}

.thumbs .thumb {
  width: 128px;
  height: 128px;
  position: relative;
  border: 1px solid #dfdfdf;
  border-radius: 10px;
}

.thumbs .thumb img {
  border-radius: 10px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.thumbs .thumb .btn-remove {
  position: absolute;
  left: -10px;
  top: -10px;
  padding: 4px;
  border: 1px solid #ff2625;
  height: 25px;
  width: 25px;
  background: #ff2625;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  box-shadow: 0 0 0 3px #fff;
}

.thumbs .thumb .btn-remove svg {
  position: relative;
  right: auto;
  left: auto;
  top: auto;
  transform: none;
}

[type="color"] {
  padding: 0;
  overflow: hidden;
}

.loader {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader img {
  object-fit: contain;
  width: 200px;
}

.basic-single svg {
  position: relative;
  top: auto;
  transform: none;
  right: auto;
}

.basic-single .select__control {
  border-color: #efefef;
  min-height: 46px;
  border-radius: 10px;
  box-shadow: none;
}

.basic-single .select__control:hover {
  border-color: #efefef;
}

.basic-single .select__placeholder {
  color: #8f9bba;
  font-size: 14px;
}

.basic-single .select__value-container {
  padding: 2px 10px;
}

.basic-single .select__option {
  font-size: 14px;
}

.basic-single .select__menu-list {
  padding: 10px 0;
}

.basic-single .select__multi-value {
  background: linear-gradient(260.02deg, #395cf5 -21.44%, #20a9f2 117.29%);
  border-radius: 50px;
  padding: 2px 5px;
}

.basic-single .select__multi-value .select__multi-value__remove svg {
  fill: #fff;
}

.basic-single .select__multi-value .select__multi-value__label {
  color: #fff;
  font-size: 13px;
}

.basic-single .select__multi-value .select__multi-value__remove {
  background: transparent;
}

.payment-logo {
  width: 160px;
  object-fit: contain;
}

.image-contain {
  background: transparent !important;
}

.image-contain label {
  border: 1px solid #efefef;
  border-radius: 10px;
}

.image-contain label > div {
  background-color: #fff;
  padding: 10px;
}

.image-contain label > div img {
  object-fit: contain;
}

.image-contain .img-pat img {
  filter: brightness(0.8);
}

body .rdt_TableRow:last-of-type {
  border: 0 !important;
}

.topCards svg {
  fill: #336ff4;
}

.topCards .col-xl-4:first-of-type {
  display: none;
}

.dropdown-menu a svg {
  width: 25px;
  height: 25px;
  background: #dfdfdf;
  border-radius: 100%;
  padding: 6px;
  margin-left: 10px;
}

.dropdown-menu a svg.edit {
  background: linear-gradient(135deg, #2cc156 0%, #71d68e 100%);
}

.dropdown-menu a svg.delete {
  background: linear-gradient(135deg, #ff2625 0%, #f77776 100%);
}

.lang-switcher .dropdown-toggle {
  display: flex;
  align-items: center;
  column-gap: 10px;
  color: #000 !important;
}

.lang-switcher .dropdown-toggle img {
  width: 20px;
  height: 20px;
  object-fit: contain;
  min-width: 20px;
}

.sidebarToggled .main-sidebar .side-nav ul li a.active ~ .arrow svg path {
  fill: #fff;
}

.main-sidebar .side-nav .side-nav-child .arrow svg path {
  fill: #a3aed0;
}

.basic-single svg {
  position: relative;
  top: auto;
  transform: none;
  right: auto;
}

.basic-single .select__control {
  border-color: #efefef;
  min-height: 46px;
  border-radius: 10px;
  box-shadow: none;
}

.basic-single .select__control:hover {
  border-color: #efefef;
}

.basic-single .select__placeholder {
  color: #8f9bba;
  font-size: 14px;
}

.basic-single .select__value-container {
  padding: 2px 10px;
}

.basic-single .select__option {
  font-size: 14px;
}

.basic-single .select__menu-list {
  padding: 10px 0;
}

.basic-single .select__multi-value {
  background: linear-gradient(260.02deg, #395cf5 -21.44%, #20a9f2 117.29%);
  border-radius: 50px;
  padding: 2px 5px;
}

.basic-single .select__multi-value .select__multi-value__remove svg {
  fill: #fff;
}

.basic-single .select__multi-value .select__multi-value__label {
  color: #fff;
  font-size: 13px;
}

.basic-single .select__multi-value .select__multi-value__remove {
  background: transparent;
}

.stores-selection .dropdown-toggle {
  height: 46px;
  border-radius: 10px;
  padding: 0 5px 0 15px;
  border: 1px solid transparent;
  box-shadow: none !important;
  transition: all 0.3s;
  font-size: 14px;
  text-transform: none;
  line-height: 46px;
  min-width: 250px;
  text-align: initial;
  display: flex;
  align-items: center;
  column-gap: 15px;
  color: #000;
  font-family: "Bahij-semibold";
}

.stores-selection .dropdown-toggle span {
  background-color: #f9f9f9;
  width: 46px;
  height: 46px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.stores-selection .dropdown-toggle::before {
  position: absolute;
  left: 18px;
  top: 18px;
  width: 9px;
  height: 9px;
  border-width: 1px 1px 0 0;
  border-style: solid;
  border-color: #a3aed0;
  border-radius: 0;
  transform: rotate(135deg);
  opacity: 1;
}

.stores-selection .show .dropdown-toggle {
  border-color: #f9f9f9;
  background: #f9f9f9 !important;
  transition: all 0.3s;
  color: #8f9bba;
}

.stores-selection .dropdown-menu {
  width: 500px;
  right: 0 !important;
  left: auto !important;
  border-color: #768cff;
  border-radius: 10px;
  margin-top: 10px;
  padding: 20px 0 0 15px;
  min-height: 216px;
  background: #f9f9f9;
}

.stores-selection .dropdown-menu a:hover {
  background: rgba(44, 133, 244, 0.1);
  color: #2c85f4;
}

.stores-selection .dropdown-menu a {
  border-radius: 5px;
  padding: 6px 13px;
  width: 50%;
  font-size: 16px;
  margin-bottom: 10px;
}

.stores-selection .dropdown-menu a img {
  position: fixed;
  left: 35px;
  top: 20px;
  width: 175px;
  height: calc(100% - 40px);
  object-fit: cover;
  border-radius: 10px;
  opacity: 0;
  transition: all 0.3s;
  background-color: #eee;
}

.stores-selection .dropdown-menu a:hover img {
  opacity: 1;
  transition: all 0.3s;
}

.stores-selection .dropdown-menu a svg {
  fill: #346cf4;
  position: absolute;
  left: 52%;
  margin-top: 2px;
  opacity: 0;
  transition: all 0.3s;
}

.stores-selection .dropdown-menu a:hover svg {
  opacity: 1;
  transition: all 0.3s;
}

.drop-scrollable {
  overflow: hidden;
  position: relative;
  max-height: 180px;
  overflow-y: auto;
  padding: 0 25px;
}

.stores-selection .dropdown-menu .drop-scrollable::before {
  content: "";
  position: fixed;
  left: 35px;
  top: 20px;
  height: calc(100% - 40px);
  width: 175px;
  border-radius: 10px;
  background-color: #c4c4c4;
  background-image: url(../assets/svgs/shop.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 50px;
  opacity: 0.2;
  z-index: -1;
}

.drop-scrollable::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.drop-scrollable::-webkit-scrollbar-track {
  background-color: #f7f7f7;
  border-radius: 15px;
}

.drop-scrollable::-webkit-scrollbar-thumb {
  background-color: #346cf4;
  border-radius: 15px;
}

.stores-selection .dropdown-menu .active-store img {
  opacity: 1;
}

.stores-selection .dropdown-menu .active-store svg {
  opacity: 1;
}

.stores-selection .dropdown-menu .active-store {
  background: rgba(44, 133, 244, 0.1);
  color: #2c85f4;
}

.stores-selection .dropdown-menu a svg {
  background: transparent;
  width: auto;
  height: auto;
  padding: 0;
  margin-left: 0;
  margin-top: 4px;
}

.hkMDrI {
  overflow: visible !important;
}

.dropdown-menu a svg.show {
  background-color: #2696f3;
}

.hkMDrI {
  overflow: visible !important;
}

.dropdown-menu a svg.show {
  background-color: #2696f3;
}

.acc-form .nav {
  margin-bottom: 30px;
  column-gap: 15px;
  justify-content: space-between;
}

.acc-form .nav .nav-link {
  background-color: #f7f7f7;
  border-radius: 10px;
  text-align: center;
  padding: 20px;
  color: #707070;
  transition: all 0.3s;
}

.acc-form .nav .nav-link.active {
  background-color: #53cc75;
  color: #fff;
  transition: all 0.3s;
}

.acc-form .nav .nav-item {
  width: 49%;
}

.themes-img {
  height: 250px;
  border-bottom: 1px solid #f4f4f4;
  position: relative;
}

.themes-img .theme-price {
  position: absolute;
  left: 20px;
  top: 20px;
  background: #f42f41;
  border-radius: 50px;
  padding: 2px 20px;
  color: #fff;
  box-shadow: 0 3px 3px rgb(0 0 0 / 10%);
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Bahij-semibold";
  font-size: 14px;
}

.themes-item .themes-content {
  position: relative;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 65px;
}

.themes-item .themes-content .btn {
  border-radius: 5px;
  height: 35px;
  line-height: 35px;
  font-size: 13px;
}

.themes-item .themes-content .btn.btn-green {
  border-color: #53cc75;
  color: #53cc75;
}

.themes-item .themes-content .btn:hover {
  background-color: #346cf4;
  border-color: #346cf4;
  color: #fff;
}

.themes-item .themes-content .btn.btn-green:hover {
  border-color: #53cc75;
  color: #53cc75;
  color: #fff;
}

.themes-item {
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 3px 6px rgb(0 0 0 / 8%);
}

.themes-img img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.themes-item .themes-content h5 {
  font-family: "Bahij-bold";
  font-size: 15px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 60%;
}

.themes-wrp {
  margin-bottom: 50px;
}

.themes-wrp .row {
  row-gap: 24px;
}

.main-sidebar .side-nav .side-nav-child .active ~ .arrow svg path {
  fill: #fff;
}

.themes-item .themes-content span {
  display: flex;
  align-items: center;
  column-gap: 10px;
  color: #53cc75;
}

.themes-item .themes-content span svg {
  fill: #53cc75;
}

.table-wrap .card-body header + div {
  overflow: visible !important;
}

.themes-wrap .col-xl-4 {
  display: none;
}

.themes-wrap .col-xl-8 {
  width: 100%;
}

.themes-wrap .row {
  row-gap: 24px;
}

.themes-wrap .themes-item {
  background: #fff;
}

.mce-content-body [class="btn"] {
  background-color: #000;
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  display: inline-block;
  cursor: pointer;
  text-decoration: none;
}

.tox-menubar,
.tox-statusbar__branding,
.tox-notifications-container {
  display: none !important;
}

.tox svg,
.creatable-select svg {
  transform: none;
  left: auto;
  right: auto;
  top: auto;
  bottom: auto;
  position: unset;
}

/* .creatable-select .select__menu {
  display: none;
} */

.side-slogan {
  font-size: 14px;
  font-family: "Bahij-semibold";
  background-color: #a3aed0;
  padding: 10px 35px;
  margin: 0 -25px 0 -10px;
  color: #fff;
}

.view-lines {
  text-transform: none;
}

.editor-wrap section {
  overflow: hidden;
  border-radius: 10px;
  margin-bottom: 20px;
}

.custom-css {
  background: #239df2;
  border-radius: 50px;
  color: #fff !important;
  padding: 2px 13px;
  font-size: 15px;
  transition: 0.3s;
}

.custom-css:hover {
  background: #53cc75;
}

.monaco-editor * {
  text-transform: none;
  font-weight: normal !important;
}

.monaco-list-rows * {
  color: #fff;
  font-size: 15px;
  font-weight: normal;
}

.approve-btn {
  height: 45px;
  line-height: 45px;
  border-radius: 5px;
  font-size: 14px;
}

.authWrap .dropdown-toggle {
  padding: 0 0 0 10px;
}

.authWrap .dropdown-toggle svg {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  margin: 0;
}

.invoice-details .row {
  margin-bottom: 30px;
  row-gap: 24px;
}

.invoice-details .img-holder {
  border-radius: 10px;
  background-color: #f7f7f7;
  overflow: hidden;
  text-align: center;
  position: relative;
  transition: 0.3s;
}

.invoice-details .img-holder button {
  width: 100%;
  height: 100%;
  border: 0;
  background: transparent;
  padding: 40px 20px;
  outline: none;
}

.invoice-details .img-holder h4 {
  margin-top: 20px;
  font-size: 16px;
  font-family: "Bahij-semibold";
  transition: 0.3s;
}

.invoice-details img {
  object-fit: contain;
  width: 60px;
  height: 60px;
  transition: 0.3s;
}

.invoice-details .img-holder:hover {
  transform: scale(0.95);
  background: linear-gradient(260.02deg, #395cf5 -21.44%, #20a9f2 117.29%);
}

.invoice-details .img-holder:hover img {
  filter: brightness(0) invert(1);
}

.invoice-details .img-holder:hover h4 {
  color: #fff;
}

.invoice-details .table {
  margin: 0;
  border: 1px solid #dfdfdf;
  table-layout: fixed;
}

.invoice-details .table th {
  background-color: #f7f7f7;
  padding: 15px;
  font-family: "Bahij-bold";
}

.invoice-details .table td {
  border: 1px solid #dfdfdf;
  padding: 20px;
}

.invoice-details .table tbody {
  border: 0;
}

.removeImg img {
  width: 100%;
  height: 100%;
}

.features .image-uploader {
  width: 75px;
  height: 75px;
  margin: 0;
}

.features .image-contain label > div img {
  height: 100%;
  width: 100%;
}

.form-group.features textarea.form-control {
  height: 110px;
}

.features > .row {
  row-gap: 24px;
}

.contact-wrap td {
  padding: 15px;
  border: 1px solid #f1f1f1;
}

.contact-wrap td strong {
  font-family: "Bahij-bold";
}

.contact-wrap td:first-of-type {
  background-color: #f7f7f7;
}

.main-sidebar .side-nav ul li a.active-child + a.active-child {
  color: #a3aed0;
}

.main-sidebar .side-nav ul li a.active-child + a.active-child::before {
  opacity: 0;
}

.contact-wrap td strong {
  font-size: 14px;
}

/* ************* DARK ************ */

.dark .main-head {
  background-color: #282c31;
  transition: all 0.3s;
  border-bottom-color: #4c4c4c;
}

.dark .form-control {
  background-color: #282c31;
  border-color: #4d4d4d;
  color: #fff;
}

.dark .searchWrap .form-group button {
  background-color: #282c31;
}

.dark .timeWrap span,
.dark .noificationWrap .menu-item-has-children > span {
  background-color: #3c4045;
  color: #fff;
}

.dark .main-sidebar .logo,
.dark .customers-list .c-item {
  border-color: #4c4c4c;
}

.dark .main-sidebar {
  background-color: #282c31;
}

.dark .content-wrap {
  background-color: #212427;
}

.dark .card {
  background-color: #282c31;
  border-color: #282c31;
}

.dark .reports-blocks .card {
  border-color: #4d4d4d;
}

.dark .card-blue-bg,
.dark .card-green-bg,
.dark .card-red-bg {
  border-color: transparent;
}

.dark .main-sidebar .side-nav ul::-webkit-scrollbar-track,
.dark .employees-list::-webkit-scrollbar-track,
.dark .noificationWrap .dropdown ul::-webkit-scrollbar-track {
  background-color: #4c4c4c;
}

.dark .rdt_Table,
.dark .rdt_TableHeadRow,
.dark .rdt_TableRow {
  background-color: #282c31 !important;
}

.dark .rdt_TableRow,
.dark .rdt_TableHeadRow {
  border-bottom: 2px solid #212427 !important;
}

.dark .table-wrap .card-body {
  border-color: #212427;
}

.dark input[type="checkbox"],
.dark input[type="radio"] {
  background-color: #212427;
  border-color: #4c4c4c;
}

.dark .rdt_TableRow:nth-of-type(odd),
.dark .rdt_Table > div {
  background-color: #212427;
  color: #fff;
}

.dark .dropdown-menu {
  background-color: #282c31;
  border-color: #4c4c4c;
}

.dark .dropdown-item:focus,
.dark .dropdown-item:hover,
.dark .noificationWrap .menu-item-has-children .dropdown-toggle span,
.dark .noificationWrap .menu-item-has-children > span,
.dark .topCards .card i {
  background-color: #3c4045;
}

.dark .rdt_Table > div > div,
.dark .rdt_TableCell div,
.dark .card-head h4,
.dark .card-head p,
.dark .rdt_TableCol *,
.dark .control-wrp .card h5,
.dark .dropdown-menu a,
.dark .authWrap .au-data h4,
.dark .topCards .card h4,
.dark .reports-blocks .card h4,
.dark .recharts-legend-item-text,
.dark .customers-list .c-item .i-data h4,
.dark .employees-list .employee-item .i-info h4,
.dark .form-group h5,
.dark .form-group strong,
.dark .modal-header .btn-close,
.dark .customer-item .i-data h4,
.dark .itemGray .r-data h4,
.dark .employee-itm .i-data h4,
.dark .employee-itm .i-data p,
.dark .flatpickr-months .flatpickr-prev-month,
.dark .flatpickr-months .flatpickr-next-month,
.dark .flatpickr-months .flatpickr-month,
.dark .flatpickr-current-month .numInputWrapper,
.dark span.flatpickr-weekday,
.dark .flatpickr-day,
.dark .modal-wrp h4,
.dark .modal-wrp p,
.dark .form-group .show-pass svg,
.dark .authWrap li svg path,
.dark .noificationWrap .i-data p,
.dark .rdt_Pagination span,
.dark .rdt_Pagination select,
.dark .rdt_TableCell span,
.dark .rdt_TableCell li,
.dark .branch-block h5,
.dark .recharts-default-tooltip * {
  color: #fff !important;
  fill: #fff;
}

.dark .flatpickr-calendar {
  background-color: #282c31 !important;
  border: 1px solid #4c4c4c !important;
  box-shadow: 0 0 25px rgb(0 0 0 / 32%);
}

.dark .flatpickr-day.inRange,
.dark .flatpickr-day.prevMonthDay.inRange,
.dark .flatpickr-day.nextMonthDay.inRange,
.dark .flatpickr-day.today.inRange,
.dark .flatpickr-day.prevMonthDay.today.inRange,
.dark .flatpickr-day.nextMonthDay.today.inRange,
.dark .flatpickr-day:hover,
.dark .flatpickr-day.prevMonthDay:hover,
.dark .flatpickr-day.nextMonthDay:hover,
.dark .flatpickr-day:focus,
.dark .flatpickr-day.prevMonthDay:focus,
.dark .flatpickr-day.nextMonthDay:focus {
  background-color: #3c4045;
  border-color: #3c4045;
}

.dark .flatpickr-current-month .numInputWrapper span.arrowDown:after {
  border-top-color: #fff;
}

.dark .flatpickr-current-month .numInputWrapper span.arrowUp:after {
  border-bottom-color: #fff;
}

.dark .flatpickr-day.nextMonthDay {
  color: #686868;
}

.dark .flatpickr-calendar.arrowBottom:after,
.dark .flatpickr-calendar.arrowBottom:before {
  border-top-color: #4c4c4c;
}

.dark .modal-content {
  background-color: #282c31;
  border: 1px solid #4c4c4c;
}

.dark .btn.btn-outline {
  border-color: #4c4c4c;
  color: #fff;
}

.dark .removeImg {
  box-shadow: 0 0 0 3px #282c31;
}

.dark .card-head i,
.dark .customer-item,
.dark .layer,
.dark .card-inner .checker input[type="checkbox"],
.dark .itemGray,
.dark .noificationWrap li a:hover {
  background-color: #3c4045;
}

.dark .card-head i svg,
.dark .main-sidebar .logo .logo-info,
.dark .noificationWrap .menu-item-has-children .dropdown-toggle span svg,
.dark .filter-wrp .form-group svg,
.dark .modal-header .btn-close,
.dark .searchWrap .form-group button svg,
.dark .noificationWrap .menu-item-has-children > span svg,
.dark .burgerBtn .toggleSidebar svg,
.dark .logo .logo-info {
  filter: brightness(0) invert(1);
}

.dark .customer-item:hover {
  background-color: #4c4c4c;
}

.dark .customer-item .i-img,
.dark .table-wrap .card-body header {
  background-color: #282c31;
}

.dark .switcher .knobs:before {
  background-color: #626262;
}

.dark .switcher .switcher-input:checked + .knobs:before {
  background-color: #ffffff;
}

.dark .btn.btn-gray {
  background-color: #3c4045;
  color: #a3aed0;
}

.dark .main-head .logo {
  background-color: #212427;
}

.dark .employee-itm {
  border-color: #4c4c4c;
}

.dark .customer-itm[status] .emp-data:before {
  box-shadow: 0 0 0 2px #282c31;
}

.dark .noificationWrap li {
  border-color: #3c4045;
}

.dark .recharts-default-tooltip {
  background-color: #3c4045 !important;
  border-color: #212427 !important;
}

.dark .recharts-rectangle.recharts-tooltip-cursor {
  fill: #3c4045;
}

.dark .recharts-cartesian-grid-horizontal {
  fill: #fff !important;
}

.dark line {
  stroke: #4c4c4c;
}

.dark .recharts-sector {
  stroke: #282c31;
}

.dark .main-sidebar .side-nav ul li a.active ~ .children {
  background-color: transparent !important;
}

.dark .table-select select {
  background-color: transparent !important;
}

.dark .table-select select option {
  background-color: #0b1e2b !important;
  color: #fff;
}

.dark .form-group.branches {
  border-color: #4d4d4d;
}

.dark .add-multi-component {
  border-color: #4d4d4d;
  background-color: #3c4045;
}

.dark .form-group.branches .form-control {
  background: #282c31;
}

.dark .control-wrp .card h5 img {
  filter: brightness(0) invert(1);
}

.dark .rdt_TableCell .branch-block {
  border-color: #3c4045;
}

.dark .dropzone {
  background-color: #3c4045;
  border-color: #515458;
  color: #fff;
}

.dark .dropzone p {
  color: inherit;
}

.dark .thumbs .thumb {
  border: 1px solid #4d4d4d;
}

.dark .rdt_Pagination {
  background: #282c31;
}

.dark .rdt_Pagination svg {
  fill: #fff;
}

.dark .store-logo {
  background-color: #212427;
  border-color: #3c4045;
}

.dark .basic-single .select__control {
  border-color: #4d4d4d;
  background-color: transparent;
}

.dark .basic-single svg {
  fill: #fff;
}

.dark .basic-single .select__menu {
  background-color: #3c4045;
}

.dark .basic-single .select__option {
  background-color: transparent;
  color: #fff;
}

.dark .basic-single .select__option--is-focused {
  background-color: #212427;
}

.dark .loader {
  background-color: #282c31;
}

.dark .image-contain label {
  border-color: #4d4d4d;
}

.dark .image-contain label > div {
  background-color: #282c31;
}

.dark.sidebarToggled .main-sidebar .side-nav ul li a.active ~ .children,
.dark.sidebarToggled .main-sidebar .side-nav ul li a ~ .children {
  background: #3c4045 !important;
}

.dark .select__single-value {
  color: #fff;
}

.dark .overlay-s {
  background-color: rgb(27 29 32 / 90%);
}

body.dark {
  background: #212427;
}

.dark .dropdown-toggle svg {
  fill: #fff;
}

.dark .lang-switcher .dropdown-toggle {
  color: #fff !important;
}

.dark .lang-switcher .dropdown-toggle img {
  filter: invert(1);
}

.dark .stores-selection .dropdown-toggle span {
  background-color: #4c4c4c;
}

.dark .stores-selection .dropdown-toggle span img {
  filter: invert(1);
}

.dark .stores-selection .dropdown-toggle {
  color: #fff;
}

.dark .stores-selection .dropdown-toggle::before {
  background: transparent;
}

.dark .stores-selection .show .dropdown-toggle {
  border-color: #747474;
  background: #4c4c4c !important;
}

.dark .acc-form .nav .nav-link {
  background-color: #4c4c4c;
  color: #fff;
}

.dark .acc-form .nav .nav-link.active {
  background-color: #2696f3;
}

.dark .themes-item {
  background: #212427;
}

.dark .themes-img {
  border-bottom-color: #282c31;
}

.dark .themes-item .themes-content h5 {
  color: #fff;
}

.dark .acc-form .nav .nav-link.active {
  background-color: #53cc75;
}

.dark .stores-selection .dropdown-menu a img {
  background-color: #474a4e;
}

.dark .themes-wrap .themes-item {
  background: #282c31;
}

.dark .form-uploader span {
  background: #282c31;
  color: #fff;
}

.dark .form-uploader {
  border-color: #4d4d4d;
}

.dark .form-uploader svg path {
  fill: #fff;
}

.dark .thumbs .thumb .btn-remove {
  box-shadow: 0 0 0 3px #282c31;
}

.dark .invoice-details .img-holder,
.dark .invoice-details .table th {
  background-color: #212427;
}

.dark .invoice-details .table,
.dark .invoice-details .table td {
  border: 1px solid #404040;
}

.dark .invoice-details .table td,
.dark .invoice-details .table th,
.dark .invoice-details .img-holder h4 {
  color: #fff;
}

.dark .main-sidebar .side-nav ul.children {
  background-color: #212427;
}

.dark .rdt_Table .dropdown-toggle img {
  filter: invert(1);
}

.dark .contact-wrap td {
  border: 1px solid #464646;
  color: #fff;
}

.dark .contact-wrap td:first-of-type {
  background-color: #212427;
}

.dark .contact-wrap td strong {
  color: #fff;
}

/* *********** LANGUAGE EN ***************** */

[dir="ltr"] .head-wrp {
  padding: 29px 38px 29px 292px;
}

[dir="ltr"] .burgerBtn {
  margin: 0 25px 0 0;
}

[dir="ltr"] .searchWrap .form-group button {
  right: 5px;
  left: auto;
}

[dir="ltr"] .main-sidebar {
  left: 0;
  right: auto;
}

[dir="ltr"] .main-sidebar .side-nav {
  padding: 40px 10px 40px 23px;
}

[dir="ltr"] .main-sidebar .side-nav ul {
  padding: 0 15px 0 0;
}

[dir="ltr"] .main-sidebar .side-nav ul li a svg,
[dir="ltr"] .main-sidebar .side-actions a svg {
  margin: 0 13px 0 0;
}

[dir="ltr"] .main-sidebar .side-nav .side-nav-child .arrow {
  right: 15px;
  left: auto;
}

[dir="ltr"] .content-wrap {
  margin: 116px 0 0 292px;
}

[dir="ltr"] .btn.btn-big svg {
  margin: 0 20px 0 0;
}

[dir="ltr"] .control-wrp .card h5 img,
[dir="ltr"] .dropdown-menu a svg {
  margin: 0 10px 0 0;
}

[dir="ltr"] .filter-wrp .form-group svg {
  left: auto;
  right: 20px;
}

[dir="ltr"] .removeImg {
  right: -10px;
  left: auto;
}

[dir="ltr"] .basic-single {
  direction: inherit;
}

[dir="ltr"] .burgerBtn .toggleSidebar {
  transform: scaleX(-1);
}

[dir="ltr"].sidebarToggled .main-head .head-wrp {
  padding: 29px 38px 29px 125px;
}

[dir="ltr"].sidebarToggled .content-wrap {
  margin: 116px 0 0 125px;
}

[dir="ltr"] {
  text-transform: capitalize;
}

.tox-notifications-container {
  scale: 0;
  visibility: hidden;
}

iframe[style*="2147483647"] {
  display: none;
}
